import React, { FC } from "react";
import Img from 'gatsby-image';
import { PokemonType } from "../../helpers/types";

import { useAllPokemon } from "../../hooks/useAllPokemon";
import { RaidBattle } from "../../lib/raid";
import { Raid, RaidLevel } from "../../models/raid";

import Box from "../box";
import Card, { CardProps } from "../card";
import Text from "../text";
import Heading from "../heading";
import TypeTag from "../pokemon/type-tag";
import MoveTag from "../pokemon/move-tag";
import RaidIcon from "./raid-icon";
import Button from "../button";
import WeatherIcon from "../weather/weather-icon";
import ArrowBoost from "../../images/icons/arrow-boost";
import Sparkle from "../../images/icons/sparkle";
import Link from "../link";
import { Weather, isBoostedForWeather } from "../../models/weather";
import SearchStringBuilder from "../search-string-builder";
import { getPokemonForm } from "../../models/pokemon";

interface Props extends CardProps {
    raid: Raid;
    currentWeather?: Weather;
    isFeatured?: boolean;
}

const RaidBoss: FC<Props> = ({ raid: { name, pokemon: pokemonRef, tier, slug }, currentWeather, ...rest }) => {
    const allPokemon = useAllPokemon()
    const { pokemonId, shiny, form, megaForm } = pokemonRef
    const { node: pokemon } = allPokemon[pokemonId - 1]
    const raidBattle = new RaidBattle(pokemon, tier, form, megaForm)
    const formInfo = getPokemonForm(pokemonRef, pokemon)
    const image = formInfo.image ? formInfo.image.fluid : null

    const style = { transformOrigin: '50% 65%', transform: 'scale(1.55)' }

    const isBoosted = () => isBoostedForWeather(raidBattle.types as PokemonType[], currentWeather)

    return (
        <Card p={4} mb={2} variant="flat" borderColor="grey.2" overflow="visible" {...rest}>
            <Box display="flex" alignItems="flex-start">
                <Box width="100px" position="relative">
                    {image && <Img style={tier === RaidLevel.Five ? style : {}} fluid={image} />}
                    <RaidIcon tier={tier} iconWidth="14px" iconSpacing={1} iconFill="grey.5" justifyContent="center" mt={3} />
                    {shiny && <Box
                        position="absolute"
                        top="0"
                        right="0"
                    >
                        <Sparkle fill="black" height="15px" />
                    </Box>}
                </Box>
                <Box ml={6}>
                    {/* <RaidIcon tier={tier} /> */}
                    <Heading as="h2" fontSize={7} m={0}>
                        <Link fontSize="inherit" to={`/raids/${slug.current}`} state={{weather: currentWeather}}>{raidBattle.name}</Link>
                    </Heading>
                    <Text fontSize={4} mt={0} mb={2} color="grey.4">CP {raidBattle.cp}</Text>
                    <Box display="flex" alignItems="center">
                        {raidBattle.types.map(type => type as PokemonType).map(type => (
                            <TypeTag
                                key={`raid-boss-${name}-${type}`}
                                type={type}
                                onlyShowIcon
                                mt={0}
                                mr={2} />
                        ))}
                        {isBoosted() && <ArrowBoost fill="primary" height="16px" />}
                    </Box>

                    <Box mt={3}>
                        <Heading as="h3" fontSize={3} mt={4} mb={0} fontWeight="bold">Catch Info</Heading>
                        <Text fontSize={2} mb={0}>LVL 20 CP: <strong>{raidBattle.perfectCpLevels.normal}</strong></Text>
                        <Box display="flex" alignItems="flex-start">
                            <Text fontSize={2} mb={0} bg={isBoosted() ? 'primary' : 'transparent'}>LVL 25 CP: <strong>{raidBattle.perfectCpLevels.boosted}</strong></Text>
                            {raidBattle.boostedWeather
                                .map(weather =>
                                    <Box key={weather} display="flex" bg='blackAlways' ml={1} borderRadius="large"><WeatherIcon height="17px" weather={weather} /></Box>
                                )
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" mt={8} mb={3} textAlign="center">
                <Heading as="h3" fontSize={3}>Weaknesses</Heading>
                <Link fontSize={2} to={`/type-effectiveness?types=${raidBattle.types.join(',')}`}>View all</Link>
            </Box>
            <Box display="flex" flexWrap="wrap">
                {raidBattle.weaknesses && Object.entries(raidBattle.weaknesses).sort(([_, a], [__, b]) => b - a).map(([type, multiplier]) => (
                    <Box display="flex" alignItems="center" key={`raid-boss-${name}-weaknesses-${type}`}>
                        <TypeTag
                            type={type as PokemonType}
                            multiplier={multiplier}
                            mt={2}
                            mr={2}
                            isBoosted={isBoostedForWeather([type as PokemonType], currentWeather)}
                        />
                    </Box>
                ))}
            </Box>

            <Heading as="h3" fontSize={3} mt={6} mb={2}>Moveset</Heading>
            <Heading as="h4" fontSize={3} mb={2} fontWeight="normal">Fast Attacks</Heading>
            <Box display="flex" flexWrap="wrap">
                {raidBattle.fastMoves.map((move, index) => (
                    <MoveTag key={`move-${move.id}`} move={move} />
                ))}
            </Box>
            <Heading as="h4" fontSize={3} mt={3} mb={2} fontWeight="normal">Charged Attacks</Heading>
            <Box display="flex" flexWrap="wrap">
                {raidBattle.chargedMoves.map((move, index) => (
                    <MoveTag key={`move-${move.id}`} move={move} />
                ))}
            </Box>
            <Button variant="secondary" mt={4} onClick={() => raidBattle.getBattleReccomendation(allPokemon)}>View Counters</Button>
            <SearchStringBuilder pokemon={[]} moveTypes={raidBattle.weaknesses ? Object.entries(raidBattle.weaknesses).map(([type]) => type as PokemonType) : []} />
        </Card>
    )
}

export default RaidBoss