import React, { FC, useState } from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Heading from "../components/heading"
import Button from "../components/button"
import Text from "../components/text"
import Search from "../components/form/search"
import RaidBoss from "../components/raid/raid-boss"
import Wrapper from "../containers/wrapper"
import WeatherSelector from "../components/weather/weather-selector"
import RaidIcon from "../components/raid/raid-icon"
import { Raid, RaidLevel, RaidNode } from "../models/raid"
import Box from "../components/box"
import { capitalize } from "../helpers/helpers"
import { Weather } from "../models/weather"

interface Props {
    data: {
        allSanityRaid: {
            edges: RaidNode[]
        }
    }
}

const Raids: FC<Props> = ({ data }) => {
    const { allSanityRaid: { edges: raids } } = data
    const [selectedWeather, setSelectedWeather] = useState<Weather>()

    const groupByTier = (acc: Record<string, Array<Raid>>, value: RaidNode) => {
        const { tier } = value.node
        if (!(tier in acc)) {
            acc[tier] = []
        }
        acc[tier].push(value.node)
        return acc
    }

    const sortBy = '5 mega 3 1'

    const sortByTier = ([levelA]: [string, Raid[]], [levelB]: [string, Raid[]]) => sortBy.indexOf(levelA) - sortBy.indexOf(levelB)

    return (
        <Wrapper>
            <SEO title={`Pokemon Go Raid Counters`} />
            <Search placeholder="Search" mb={4} />

            {/* Filter */}
            <Box display="flex" alignItems="center">
                <Text fontSize={2} m={0} mr={3}>Raid Level</Text>
                <Button variant="secondary" width="auto" py={2} mr={2}>
                    <RaidIcon tier={RaidLevel.One} iconWidth="12px" iconFill="white" />
                </Button>
                <Button variant="secondary" width="auto" py={2} mr={2}>
                    <RaidIcon tier={RaidLevel.Three} iconWidth="12px" iconFill="white" />
                </Button>
                <Button variant="secondary" width="auto" py={2} mr={2}>
                    <RaidIcon tier={RaidLevel.Five} iconWidth="12px" iconFill="white" />
                </Button>
                <Button variant="secondary" width="auto" py={2}>
                    Mega
                </Button>
            </Box>

            <WeatherSelector value={selectedWeather} onChange={selection => setSelectedWeather(selection)} mb={6} />
            { Object.entries(raids.reduce(groupByTier, {})).sort(sortByTier).map(([level, raids]) =>
                <Box key={`raids-${level}`}>
                    <Heading as="h2" fontSize={5} mb={3}>Tier: {capitalize(level)}</Heading>
                    <Box key={`raids-${level}`} display={{ xs: 'block', sm: 'grid' }} gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr', lg: '1fr 1fr 1fr' }} gridColumnGap={4}>
                        {raids.map((raid, index) => <RaidBoss currentWeather={selectedWeather} key={`raids-${level}-${index}`} raid={raid} mb={8} />)}
                    </Box>
                </Box>
            )
            }

        </Wrapper>
    )
}

export const query = graphql`
  query {
    allSanityRaid {
        edges {
            node {
                ...RaidFields
            }
        }
    }
  }
`

export default Raids